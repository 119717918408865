jQuery.validator.addMethod("dni", function(value, element) {
    var cadenadni="TRWAGMYFPDXBNJZSQVHLCKET";
    var let = value.substr(value.length-1,1);
    if(/^([0-9]{8})*[a-zA-Z]+$/.test(value)){
        var numero = value.substr(0,value.length-1) % 23;
        letra=cadenadni.substring(numero,numero+1);
        if (letra == let || letra.toLowerCase() == let) return true;
        return false;
    } else if (/^[XYZ]{1}/.test(value)) {
        var reemplazar = new Array("X", "Y", "Z");
        var por = new Array("0", "1", "2");
        numero=value
        for (var i=0; i<reemplazar.length; i++) {
            numero = numero.replace(reemplazar[i], por[i]);
        }
        var numero = numero.substr(0,value.length-1) % 23;
        var letra = cadenadni.substring(numero, numero + 1);
        if (letra == let || letra.toLowerCase() == let) return true;
        return false;
    } else if (/^[xyz]{1}/.test(value)) {
        var reemplazar = new Array("x", "y", "z");
        var por = new Array("0", "1", "2");
        numero=value
        for (var i=0; i<reemplazar.length; i++) {
            numero = numero.replace(reemplazar[i], por[i]);
        }
        var numero = numero.substr(0,value.length-1) % 23;
        var letra = cadenadni.substring(numero, numero + 1);
        if (letra == let || letra.toLowerCase() == let) return true;
        return false;
    }
    return this.optional(element);
}, "Por favor escribe un DNI válido");






jQuery.validator.addMethod("emailval", function(value, element) {
    var val = true;
    (jQuery).ajax({
        url: window.urlCheckEmail,
        type: 'post',
        async: false,
        data: {
            username: function() {
                return $('#form_email').val();
            }
        }
    }).done(function(data, textStatus) {
        if (data == 'false') {
            val = false;
        }
    });
    return val;
}, "Por favor escribe un email válido");